import React from "react";
import { SEO, PageLayout } from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class AboutTheFirmPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout>
          <Grid
            stackable={true}
            className={"component-section-container about-the-firm-page"}
            verticalAlign={"middle"}
            textAlign={"center"}
            centered={true}
          >
            <Grid.Column width={14} textAlign={"left"}>
              <div>
                <h1>About the Firm</h1>
                <p>
                  Attorney John T. Zogby is a graduate of Boston College (1987)
                  where he was captain of the varsity wrestling team. Attorney
                  Zogby graduated from the New England School of Law in Boston,
                  MA and was admitted to the Massachusetts Bar in 1992. After
                  working for a Boston insurance defense firm, Attorney Zogby
                  opened the law firm in his hometown of Milford, where he was
                  recently inducted into the Milford High School Athletic Hall
                  of Fame. Attorney Zogby represents clients in the District and
                  Superior Courts of most counties in Eastern and Central
                  Massachusetts, as well as the U.S. Federal District Court.
                  Attorney Zogby has also appeared before the Massachusetts
                  Supreme Judicial Court.
                </p>
              </div>
            </Grid.Column>
            <Grid.Column width={14} textAlign={"center"}>
              <Image
                size={"large"}
                className={"about-image"}
                bordered={true}
                rounded={true}
                centered={true}
                src={
                  "https://fisherman.gumlet.io/public/johntzogbylawoffice/IMG_2376.jpg"
                }
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "About the Firm" }) {
      title
    }
  }
`;
